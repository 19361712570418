<script setup>
import { computed } from 'vue';

const props = defineProps({
  src: {
    type: [String, null],
    default: null,
  },
  height: {
    type: Number,
    default: 0,
  },
  hasBorderRadius: {
    type: Boolean,
    default: false,
  },
  borderSizeClass: {
    type: String,
    default: 'emobg-border-2',
  },
  isOverlayHidden: {
    type: Boolean,
    default: false,
  },
  onClickImage: {
    type: Function,
    default: () => {},
  },
});

const imageStyle = computed(() => (props.height > 0 ? { maxHeight: `${props.height}px` } : ''));
</script>
<template>
  <div
    v-bind="$attrs"
    class="DamageImageTemplate position-relative"
  >
    <div
      v-if="src"
      @click="onClickImage"
    >
      <img
        :src="src"
        alt=""
        :class="['DamageImage', { 'emobg-border-radius-small': hasBorderRadius }]"
        :style="imageStyle"
      >
      <div
        v-if="!isOverlayHidden"
        :class="['DamageImage--overlay', borderSizeClass]"
      />
    </div>
    <span v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </span>
  </div>
</template>
<style lang="scss">
.DamageImageTemplate {
  max-width: fit-content;

  .DamageImage {
    width: 100%;
    height: auto;
    max-height: calc(100vh - 200px);

    &--overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 60%;
      aspect-ratio: 1 / 1;
      background: rgba(230, 0, 15, 0.1);
      border-color: #e6000f;
      border-style: dashed;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
