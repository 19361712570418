var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._b(
      { staticClass: "DamageImageTemplate position-relative" },
      "div",
      _vm.$attrs,
      false
    ),
    [
      _vm.src
        ? _c("div", { on: { click: _vm.onClickImage } }, [
            _c("img", {
              class: [
                "DamageImage",
                { "emobg-border-radius-small": _vm.hasBorderRadius },
              ],
              style: _setup.imageStyle,
              attrs: { src: _vm.src, alt: "" },
            }),
            !_vm.isOverlayHidden
              ? _c("div", {
                  class: ["DamageImage--overlay", _vm.borderSizeClass],
                })
              : _vm._e(),
          ])
        : _c("span", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }