<script setup>
import { ref } from 'vue';
import { GenericModalComponent } from '@/components';
import DamageImageTemplate from './DamageImageTemplate';

defineProps({
  image: {
    type: String,
    required: true,
  },
});

const isOverlayHidden = ref(false);

const toggleCircleOverlay = () => {
  isOverlayHidden.value = !isOverlayHidden.value;
};
</script>

<template>
  <GenericModalComponent
    title="Damage picture preview"
    :header="{ isClosable: true }"
    class="PreviewDamageImageModal v1-MuiModal--adaptative"
    data-test-id="preview-damage-image-modal"
    v-on="$listeners"
  >
    <template #body>
      <div class="d-flex flex-fill justify-content-center py-2 emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-ground-lighter">
        <DamageImageTemplate
          :src="image"
          :is-overlay-hidden="isOverlayHidden"
        />
      </div>
      <div class="d-flex justify-content-end">
        <ui-button
          :face="FACES.outline"
          :color="GRAYSCALE.inkLight"
          circle
          data-test-id="hide-show-button"
          class="pt-2"
          @clickbutton="toggleCircleOverlay"
        >
          <ui-icon
            :icon="isOverlayHidden ? ICONS.show : ICONS.hide"
            :size="ICONS_SIZES.medium"
            class="d-flex flex-column align-center"
          />
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
