var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.GenericModalComponent,
    _vm._g(
      {
        staticClass: "PreviewDamageImageModal v1-MuiModal--adaptative",
        attrs: {
          title: "Damage picture preview",
          header: { isClosable: true },
          "data-test-id": "preview-damage-image-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-fill justify-content-center py-2 emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-ground-lighter",
                  },
                  [
                    _c(_setup.DamageImageTemplate, {
                      attrs: {
                        src: _vm.image,
                        "is-overlay-hidden": _setup.isOverlayHidden,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end" },
                  [
                    _c(
                      "ui-button",
                      {
                        staticClass: "pt-2",
                        attrs: {
                          face: _vm.FACES.outline,
                          color: _vm.GRAYSCALE.inkLight,
                          circle: "",
                          "data-test-id": "hide-show-button",
                        },
                        on: { clickbutton: _setup.toggleCircleOverlay },
                      },
                      [
                        _c("ui-icon", {
                          staticClass: "d-flex flex-column align-center",
                          attrs: {
                            icon: _setup.isOverlayHidden
                              ? _vm.ICONS.show
                              : _vm.ICONS.hide,
                            size: _vm.ICONS_SIZES.medium,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }